import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {filter, map, switchMap, take} from 'rxjs/operators';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {DateTime} from 'luxon';
import {sortBy} from 'lodash';
import {AngularFirestore,} from '@angular/fire/compat/firestore';

import {UserService} from './user.service';
import {Group, SafetyAlert, SaManagerAssignment, SaUserAssignment, UserObj,} from '../../../../../core/models';
import {environment} from '../../../environments/environment';
import {TenantService} from './tenant.service';
import {createUserObjFromUserRowRecord, UserRow,} from '../../../../../core/dto/userRowRecord';
import {MyTeamService} from './my-team.service';
import {SortOrder} from '../../../../../core/enums/sortOrder';
import {PersonalSaFilter} from "../../../../../core/models/personal-sa-filter";

export interface UserWithNote {
  value: UserObj;
  disabled: boolean;
  note: string;
}

@Injectable({ providedIn: 'root' })
export class SafetyAlertService {
  private _newAlertsExist = new BehaviorSubject<boolean>(false);
  private _userIsSaManager = new BehaviorSubject<boolean>(null);
  private _newAlertsToView = new BehaviorSubject<{
    data: SaUserAssignment[];
    totalCount: number;
  }>({ data: [], totalCount: null });
  private _newAlertsToDeliver = new BehaviorSubject<{
    data: SaManagerAssignment[];
    totalCount: number;
  }>({
    data: [],
    totalCount: null,
  });

  public readonly newAlertsToView = this._newAlertsToView.asObservable();
  public readonly newAlertsToDeliver = this._newAlertsToDeliver.asObservable();
  public readonly userIsSaManager = this._userIsSaManager.asObservable();
  public readonly newAlerts = this._newAlertsExist.asObservable();

  constructor(
    private userService: UserService,
    private tenantService: TenantService,
    private myTeamService: MyTeamService,
    private http: HttpClient,
    private afs: AngularFirestore,

  ) {
    this.setManagerStatus();
  }

  userAndTenantLoaded() {
    // Get user and tenant in order to fetch correct alerts
    return combineLatest([
      this.userService.user,
      this.tenantService.tenant,
    ]).pipe(
      filter(([user, tenant]) => {
        if (user && tenant) {
          return true;
        }
        return false;
      }),
      take(1),
    );
  }

  /**
   * Initiates fetch of undelivered Safety Engagements from sql assigned to a single user to deliver or view,
   * triggering the newAlertsToView and newAlertsToDeliver observables to emit values
   */
  fetchNewAlerts(numberToLoad: number = 15): void {
    this.updateNewAlertsToView(0, numberToLoad);
    this.updateNewAlertsToDeliver(0, numberToLoad);
  }

  public updateNewAlertsToView(page: number, pageSize: number) {
    this.fetchNewAlertsToView(page, pageSize).subscribe((newAlertsToView) => {
      this._newAlertsToView.next(newAlertsToView);
    });
  }

  public updateNewAlertsToDeliver(page: number, pageSize: number) {
    this.fetchNewAlertsToDeliver(page, pageSize).subscribe(
      (newAlertsToDeliver) => {
        this._newAlertsToDeliver.next(newAlertsToDeliver);
      },
    );
  }

  /**
   * Fetches undelivered Safety Engagements assigned to the logged in user to view,
   * returning an observable that emits an array of saUserAssignments and a count
   */
  public fetchNewAlertsToView(
    page: number,
    pageSize: number,
  ): Observable<{ data: SaUserAssignment[]; totalCount: number }> {
    return this.userAndTenantLoaded().pipe(
      switchMap(() => {
        const sqlId = this.userService.currentUser.sqlId;
        const url = `${environment.apiV2}/sa-user-assignments/paginated`;
        let queryParams = new HttpParams({
          fromObject: {
            page: page.toString(),
            limit: pageSize.toString(),
            sortBy: 'createdOn',
            sortDirection: SortOrder.descending,
            userId: sqlId.toString(),
            delivered: 'false',
          },
        });
        return this.http.get<{ data: SaUserAssignment[]; totalCount: number }>(
          url,
          {
            params: queryParams,
          },
        );
      }),
    );
  }

  /**
   * Fetches undelivered Safety Engagements assigned to the logged in user to deliver,
   * returning an observable that emits an array of saManagerAssignments and a count
   */
  fetchNewAlertsToDeliver(
    page: number,
    pageSize: number,
  ): Observable<{ data: SaManagerAssignment[]; totalCount: number }> {
    return this.userAndTenantLoaded().pipe(
      switchMap(() => {
        const sqlId = this.userService.currentUser.sqlId;
        const url = `${environment.apiV2}/sa-manager-assignments/paginated`;
        let queryParams = new HttpParams({
          fromObject: {
            page: page.toString(),
            limit: pageSize.toString(),
            sortBy: 'createdOn',
            sortDirection: SortOrder.descending,
            userId: sqlId.toString(),
            completed: 'false',
          },
        });
        return this.http.get<{
          data: SaManagerAssignment[];
          totalCount: number;
        }>(url, {
          params: queryParams,
        });
      }),
    );
  }

  async fetchNewAlertCount(): Promise<void> {
    const url = `${environment.apiV2}/users/has-new-alerts`;
    await this.userAndTenantLoaded().toPromise();
    const newAlertsExist = await this.http.get<boolean>(url).toPromise();
    this._newAlertsExist.next(newAlertsExist);
  }

  async setManagerStatus(): Promise<void> {
    const url = `${environment.apiV2}/users/is-sa-manager`;
    await this.userAndTenantLoaded().toPromise();
    const userIsManager = await this.http.get<boolean>(url).toPromise();
    this._userIsSaManager.next(userIsManager);
    if (userIsManager) {
      this.myTeamService.triggerMyTeamRequest();
    }
  }

  /**
   * Fetches delivered Safety Engagements assigned to a single user,
   * returning a promise that resolves to an array of saUserAssignments and a count
   * @param userSqlId sqlId of the user to fetch
   */
  async getOldSaUserAssignmentsPaginated(
    page: number = 1,
    pageSize: number = 5,
    search: string = '',
    keywords: string[] = [],
  ): Promise<{ data: SaUserAssignment[]; totalCount: number }> {
    const sqlId = this.userService.currentUser.sqlId;
    const url = `${environment.apiV2}/sa-user-assignments/paginated`;
    let queryParams = new HttpParams({
      fromObject: {
        page: page.toString(),
        limit: pageSize.toString(),
        sortBy: 'createdOn',
        sortDirection: SortOrder.descending,
        userId: sqlId.toString(),
        delivered: 'true',
        search: search || '',
      },
    });
    // handles adding searchBy via array
    for (const searchBy of ['title']) {
      queryParams = queryParams.append('searchBy[]', searchBy);
    }

    if (keywords.length > 0) {
      keywords.forEach((keyword) => {
        queryParams = queryParams.append('keywords[]', keyword);
      });
    }
    await this.userAndTenantLoaded().toPromise();
    return this.http
      .get<{ data: SaUserAssignment[]; totalCount: number }>(url, {
        params: queryParams,
      })
      .toPromise();
  }

  /**
   * Fetches completed Safety Engagements assigned to a single user to deliver,
   * returning a promise that resolves to an array of saManagerAssignments and a count
   * @param userSqlId sqlId of the user to fetch
   */
  async getOldSaManagerAssignmentsPaginated(
    page: number = 0,
    pageSize: number = 5,
    search: string = '',
    keywords: string[] = [],
  ): Promise<{ data: SaManagerAssignment[]; totalCount: number }> {
    const sqlId = this.userService.currentUser.sqlId;
    const url = `${environment.apiV2}/sa-manager-assignments/paginated`;
    let queryParams = new HttpParams({
      fromObject: {
        page: page.toString(),
        limit: pageSize.toString(),
        sortBy: 'createdOn',
        sortDirection: SortOrder.descending,
        userId: sqlId.toString(),
        completed: 'true',
        search: search || '',
      },
    });
    // handles adding searchBy via array
    for (const searchBy of ['title']) {
      queryParams = queryParams.append('searchBy[]', searchBy);
    }
    if (keywords.length > 0) {
      keywords.forEach((keyword) => {
        queryParams = queryParams.append('keywords[]', keyword);
      });
    }
    await this.userAndTenantLoaded().toPromise();

    return this.http
      .get<{ data: SaManagerAssignment[]; totalCount: number }>(url, {
        params: queryParams,
      })
      .toPromise();
  }

  /**
   * Fetches Safety Engagement assignment from api v2
   * returning a promise that resolves to the Safety Engagement
   * Stripping away assignment info
   * @param id sql id of assignment
   */
  public getAlertFromAssignmentById(id: number): Promise<SafetyAlert> {
    const url = `${environment.apiV2}/sa-user-assignments/${id}`;
    return this.userAndTenantLoaded()
      .pipe(
        switchMap(() =>
          this.http.get<SaUserAssignment>(url).pipe(
            map((assignment: SaUserAssignment) => {
              return new SafetyAlert(assignment.safetyAlert);
            }),
          ),
        ),
      )
      .toPromise();
  }

  /**
   * Fetches Safety Engagement manager from api v2
   * returning a promise that resolves to the Sa Manager
   * @param id sql id of manager record
   */
  public getAlertManagerById(id: number): Promise<SaManagerAssignment> {
    const url = `${environment.apiV2}/sa-manager-assignments/${id}`;
    return this.userAndTenantLoaded()
      .pipe(switchMap(() => this.http.get<SaManagerAssignment>(url)))
      .toPromise();
  }

  getPaginatedUsersWithAssignment(
    safetyAlertId: number,
    page: number,
    limit: number,
    search?: string,
    locations?: string[],
    costCenters?: string[],
    groups?: Group[]
  ): Observable<{ data: UserRow[], totalCount: number }> {
    const url = `${
      environment.apiV2
    }/users/paginated/assigned/${safetyAlertId.toString()}`;
    let params = new HttpParams({
      fromObject: {
        sortBy: 'lastName',
        sortDirection: SortOrder.ascending,
        limit: limit.toString(),
        page: page.toString(),
      },
    });
    if (search) {
      params = params.append('search', search);
      ['firstName', 'lastName', 'employeeId'].forEach((field) => {
        params = params.append('searchBy[]', field);
      });
    }
    locations?.forEach((location) => {
        params = params.append('locations[]', location);
    });
    costCenters?.forEach((costCenter) => {
      params = params.append('costCenters[]', costCenter)
    })
    groups?.forEach((group) => {
      params = params.append('groups[]', group.id);
    });

    return this.http
      .get<{ data: UserRow[]; totalCount: number }>(url, { params })
      .pipe(
        map((response) => {
          return response
        }),
      );
  }

  // Search users in logged in user's group and child groups
  // who are assigned to receive the given Safety Engagement
  // but have not received it yet.
  // sorted in alpha order ASC by last name.
  // Excludes logged in user and their team.
  public async searchMyGroupAssigned(
    limit: number,
    offset: number,
    safetyAlertId: number,
    search?: string,
  ): Promise<UserObj[]> {
    const searchByFields = ['firstName', 'lastName', 'employeeId'];
    const url = `${
      environment.apiV2
    }/users/safety-alerts/${safetyAlertId.toString()}/paginated`;
    // First page is zero
    const page = this.getPageFromOffset(limit, offset);
    let queryParams = new HttpParams({
      fromObject: {
        limit: limit.toString(),
        page: page.toString(),
        status: 'assigned',
        sortBy: 'lastName',
        sortDirection: SortOrder.ascending,
        excludeTeam: 'true',
        relatedGroupsOnly: 'true',
      },
    });
    if (search) {
      queryParams = queryParams.append('search', search);
      searchByFields.forEach((searchByField) => {
        queryParams = queryParams.append(`searchBy[]`, searchByField);
      });
    }
    return this.userAndTenantLoaded()
      .pipe(
        switchMap(() =>
          this.http
            .get<{ data: UserRow[]; totalCount: number }>(url, {
              params: queryParams,
            })
            .pipe(
              map((response: { data: UserRow[]; totalCount: number }) => {
                return response.data.map((user) =>
                  createUserObjFromUserRowRecord(user),
                );
              }),
            ),
        ),
      )
      .toPromise();
  }

  // Returns all array of UserWithNote objects containing the users
  public getMyTeamsAssignments(safetyAlertId: number): Promise<UserWithNote[]> {
    const sqlId = this.userService.currentUser.sqlId;
    const url = `${
      environment.apiV2
    }/users/my-team/assigned/${safetyAlertId.toString()}`;
    let queryParams = new HttpParams({
      fromObject: {
        managerId: sqlId.toString(),
      },
    });
    return this.userAndTenantLoaded()
      .pipe(
        switchMap(() =>
          this.http.get<UserRow[]>(url, { params: queryParams }).pipe(
            map((teamUsers: UserRow[]) => {
              const result = teamUsers.map((user) => {
                return SafetyAlertService.getUserWithNoteFromUser(user);
              });
              return sortBy(result, ['disabled', 'value.lastName']);
            }),
          ),
        ),
      )
      .toPromise();
  }

  public static generateDisabledAndNote(user: any) {
    let disabled = false;
    let note = '';
    if (user.saAssignmentStatus === 'unassigned') {
      disabled = true;
      note = 'Not Assigned';
    }
    if (user.saAssignmentStatus === 'completed') {
      const dateDelivered = new Date(user.saUserAssignments.dateDelivered);
      const dateString = DateTime.fromJSDate(dateDelivered).toLocaleString();
      disabled = true;
      note = `Completed on ${dateString}`;
    }
    return { disabled, note };
  }

  public static getUserWithNoteFromUser(user: UserRow) {
    const value = createUserObjFromUserRowRecord(user);
    let disabled = false;
    let note: string = null;
    if (!user || !user.saUserAssignments) {
      disabled = true;
      note = 'Not assigned';
    } else if (
      user.saUserAssignments &&
      user.saUserAssignments.dateDelivered
    ) {
      const dateDelivered = new Date(
        user.saUserAssignments.dateDelivered,
      );
      const dateString =
        DateTime.fromJSDate(dateDelivered).toLocaleString();
      disabled = true;
      note = `Completed on ${dateString}`;
    }
    return { value, disabled, note };
  }

  // Adds dateCompleted to saManagerAssignment.
  // Finds saUserAssignment for each given userId and adds dateDelivered and deliveringManagerId
  public deliverAlert(managerAssignmentId: number, recipientIds: number[]) {
    const url = `${
      environment.apiV2
    }/sa-manager-assignments/complete/${managerAssignmentId.toString()}`;
    return this.userAndTenantLoaded()
      .pipe(switchMap(() => this.http.post(url, { userIds: recipientIds })))
      .toPromise();
  }

  private getPageFromOffset(pageSize: number, offset: number): number {
    if (offset) {
      return Math.floor(offset / pageSize);
    }
    return 0;
  }

  public getGroupsForTenant(tenantId: string) {
    return this.afs.collection<Group>(`tenants/${tenantId}/groups`)
      .valueChanges()
      .pipe(map(groups => groups));
  }

  public getLocationsForTenant(tenantId: string) {
    return this.afs.collection<string>(`tenants/${tenantId}/locations`)
      .get().toPromise().then(doc => doc.docs.map(d => d.id));
  }

  public getCostCentersForTenant(tenantId: string) {
    return this.afs.collection<string>(`tenants/${tenantId}/cost-centers`)
      .get().toPromise().then(doc => doc.docs.map(d => d.id));
  }

  public async saveFiltersForUser(filters: PersonalSaFilter) {
    let newDoc = await this.afs.collection('tenants').doc(this.userService.currentUser.tenantId).collection('saved-sa-filters').doc();
    await newDoc.set({...filters, id: newDoc.ref.id})
  }

  // tslint:disable-next-line:no-shadowed-variable
  async updateFilter(filter: any): Promise<void> {
    if (!filter || !filter.id) {
      throw new Error('Invalid filter: Missing ID.');
    }

    const docRef = this.afs.collection('tenants').doc('ns').collection('saved-sa-filters').doc(filter.id);

    const firestoreData = {
      active: filter.active,
      archived: filter.archived,
      createdBy: filter.createdBy,
      updatedBy: filter.updatedBy,
      createdOn: filter.createdOn,
      lastUpdatedOn: filter.lastUpdatedOn,
      filters: filter.filters
    };
    await docRef.update(firestoreData);
  }

  public async deleteFilter(filter: PersonalSaFilter) {
    let existingDoc = await this.afs.collection('tenants')
      .doc(this.userService.currentUser.tenantId).collection('saved-sa-filters').doc(filter.id);
    await existingDoc.delete();
  }

  public getSavedFilterForUser(): Promise<PersonalSaFilter | null> {
    const user = this.userService.currentUser;

    if (!user || !user.tenantId) {
      console.error('getSavedFilterForUser: currentUser or tenantId is null');
      return Promise.resolve(null);
    }

    return this.afs.collection('tenants')
      .doc(user.tenantId)
      .collection('saved-sa-filters', (ref) =>
        ref.where('createdBy', '==', user.id)
      ).get()
      .pipe(
        map(t => {
          return t.docs?.length > 0 ? t.docs[0].data() as PersonalSaFilter : null;
        })
      ).toPromise();
  }

  public loadUsers(
    pageSize: number,
    offset: number,
    safetyAlertId?: number,
    search?: string,
    locations?: string[],
    costCenters?: string[],
    otherGroups?: Group[],
    ignoreExclusions: boolean = false,
  ): Promise<{ data: UserObj[]; totalCount: number }> {
    return this.myTeamService.searchMyGroup(pageSize, offset, safetyAlertId, search, locations, costCenters, otherGroups, ignoreExclusions);
  }
}
